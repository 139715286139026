import { HttpClient, HttpResponse } from '@angular/common/http';
import { InjectorInstance } from '@app/http.module';
import { environment } from '@env/environment';
import { ReportFilter } from '@reporting/interfaces/report-filter.interface';
import { stringify } from 'qs/dist/qs';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class Query {
    private httpClient: HttpClient;

    constructor(
        protected name: string,
        protected id: string | null = null,
        protected filters: ReportFilter[] = [],
        protected page: number | null = null,
        protected sort: string | null = null,
        protected sortDirection: 'ASC' | 'DESC' | null = null,
        protected additionalParams?: [string, string][]
    ) {
        this.httpClient = InjectorInstance.get<HttpClient>(HttpClient);
    }

    /**
     * Used to fetch JSON data types. If you need a BLOB use fetchResponse()
     */
    fetchJson(): Promise<any> {
        return this.fetch('application/json', 'json')
            .pipe(
                map((res) => {
                    if (!res.ok) {
                        throw new Error('Could not load report');
                    }
                    return res.body;
                })
            )
            .toPromise();
    }

    /**
     * Used to fetch BLOB data types. If you need a JSON use fetchJson()
     */
    fetchResponse(contentType: string): Promise<HttpResponse<Blob>> {
        return this.fetch(contentType, 'blob').toPromise() as Promise<HttpResponse<Blob>>;
    }

    protected fetch(
        contentType = 'application/json',
        responseType: 'json' | 'blob'
    ): Observable<HttpResponse<unknown>> {
        // reduce array of ReportFilter into key->value array for HTTP request
        const filters = this.filters.reduce((obj, item: ReportFilter) => ((obj[item.name] = item.value), obj), {});
        const params: { [key: string]: Record<string, unknown> | string } = { filters: filters };
        if (this.page) {
            params['page'] = { number: this.page };
        }
        if (this.sort) {
            params['sort'] = { column: this.sort };
            if (this.sortDirection) {
                params['sort'].direction = this.sortDirection;
            }
        }

        if (this.additionalParams?.length) {
            this.additionalParams.forEach((param) => {
                params[param[0]] = param[1];
            });
        }

        const url =
            [environment.api, 'v2', 'reports', this.name, this.id, 'data'].filter((i) => i).join('/') +
            '?' +
            stringify(params);

        // httpClient.get is an overloaded function so unfortunately it doesn't accept responseType as a 'json' | 'blob' type
        // This ternary ensures that responseType is a static string when we call get so that TS doesn't complain
        return responseType === 'blob'
            ? this.httpClient.get(url, {
                  observe: 'response',
                  headers: { 'Content-Type': contentType },
                  responseType,
              })
            : this.httpClient.get(url, {
                  observe: 'response',
                  headers: { 'Content-Type': contentType },
                  responseType,
              });
    }
}
